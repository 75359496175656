<script lang="ts">
const showBanner = () => {
  const _hsp = window._hsp || []
  _hsp.push(['showBanner'])
}
</script>

<footer>
  <div class="footer_main_columns">
    <div class="logo">
      <a href="/">
        <img src="/icons/logo.svg" alt="Home" />
      </a>
    </div>
    <div class="footer_columns">
      <div class="footer_column">
        <h4>Services</h4>
        <ul>
          <li><a href="/solutions#offerings">Offerings</a></li>
          <li><a href="/solutions#clients">Clients</a></li>
        </ul>
      </div>
      <div class="footer_column">
        <h4>Lumiere</h4>
        <ul>
          <li>
            <a data-sveltekit-reload href="/platform#video-management">
              Video Management
            </a>
          </li>
          <li>
            <a data-sveltekit-reload href="/platform#interactive-content">
              Viewer Reactions
            </a>
          </li>
          <li>
            <a data-sveltekit-reload href="/platform#data">Data Discovery</a>
          </li>
        </ul>
      </div>
      <div class="footer_column">
        <h4>Case Studies</h4>
        <ul>
          <li><a href="/case-studies">Client Studies</a></li>
        </ul>
      </div>
      <div class="footer_column">
        <h4>About Us</h4>
        <ul>
          <li><a href="/about#work-with-us">Work With Us</a></li>
          <li><a href="/about#locations">Locations</a></li>
          <li><a href="/about#history">History</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="copy_social_wrapper">
    <div class="copyright">
      <p>© Latitude {new Date().getFullYear()}</p>
      <p><a href="/privacy-policy">Privacy Policy</a></p>
      <p><a href="/terms-and-conditions">Terms & Conditions</a></p>
      <button id="hs_show_banner_button" on:click={showBanner}>
        Cookie Settings
      </button>
    </div>
    <div class="social_icons">
      <a
        href="https://medium.com/@Latitude.Research"
        target="_blank"
        rel="noreferrer">
        <img src="/icons/medium.svg" alt="Medium" />
      </a>
      <a
        href="https://www.linkedin.com/company/latitude-research"
        target="_blank"
        rel="noreferrer">
        <img src="/icons/linkedIn.svg" alt="LinkedIn" />
      </a>
      <a
        href="https://www.instagram.com/latitudexlumiere/"
        target="_blank"
        rel="noreferrer">
        <img src="/icons/instagram.svg" alt="Instagram" />
      </a>
      <a
        href="https://twitter.com/latitudelumiere"
        target="_blank"
        rel="noreferrer">
        <img src="/icons/twitter.svg" alt="Twitter" />
      </a>
    </div>
  </div>
</footer>

<style>
footer {
  padding: 0 30px 40px;
  margin: 200px 0 0;
}

#hs_show_banner_button {
  margin: 0;
  background-color: initial;
  border: none;
  color: #a3a1bb;
}

#hs_show_banner_button:hover {
  color: var(--hoverOrange);
}

@media only screen and (min-width: 1024px) {
  footer {
    padding: 0 100px 40px;
  }

  .logo img {
    position: relative;
    bottom: 17px;
    margin-right: 20px;
  }
}

.footer_main_columns {
  display: grid;
  grid-template-rows: 100px 1fr;
}

@media only screen and (min-width: 1280px) {
  .footer_main_columns {
    grid-template-rows: unset;
    grid-template-columns: repeat(2, 1fr);
  }
}

.footer_columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 80px;
}

@media only screen and (min-width: 1280px) {
  .footer_columns {
    grid-template-columns: repeat(4, minmax(150px, auto));
    grid-column-gap: 75px;
    grid-row-gap: unset;
  }
}

.footer_column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer_column h4 {
  font-size: 21px;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin: 0 0 15px;
  line-height: 24.36px;
}

.footer_column ul li {
  font-weight: 400;
  font-size: 17px;
  line-height: 22.95px;
  margin: 0 0 5px;
}

.copy_social_wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin: 100px 0 0;
  font-size: 14px;
  color: #a3a1bb;
}

.copyright {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.copyright p {
  margin: 0;
}

.social_icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 200px;
  margin: 0 0 20px;
}

.social_icons a {
  width: fit-content;
}

@media only screen and (min-width: 1280px) {
  .copy_social_wrapper {
    align-items: center;
    justify-content: space-between;
    margin: 300px 0 0;
    flex-direction: row;
    font-size: 17px;
  }

  .copyright {
    max-width: 650px;
  }

  .social_icons {
    grid-column-gap: 15px;
    max-width: unset;
    margin: unset;
  }
}

.social_icons img {
  object-fit: contain;
  width: 20px;
  height: 20px;
}
</style>
