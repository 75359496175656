<script lang="ts">
import { browser } from '$app/environment'
import { preloadData } from '$app/navigation'

import { page } from '$app/stores'

$: path = $page.url.pathname

let yScroll = 0

const links: Record<string, { label: string; img?: string }> = {
  '/solutions': {
    label: 'Services',
  },
  '/platform': {
    label: 'Lumiere',
  },
  '/case-studies': {
    label: 'Case Studies',
  },
  '/about': {
    label: 'About Us',
  },
  '/contact-us': {
    label: 'Contact',
  },
}

if (browser) {
  preloadData('/')
}
</script>

<svelte:window bind:scrollY={yScroll} />
<nav class="desktopNavigation">
  <div
    class="mask"
    class:bottomLine={path !== '/' &&
      path !== '/platform' &&
      path !== '/contact-us' &&
      yScroll <= 50} />
  <div
    class="bg-mask"
    style={yScroll > 150
      ? 'background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 96%, rgba(0,0,0,0) 100%);'
      : 'background-color:transparent'} />
  <div class="navigationContent">
    <div class="logo">
      <a href="/"><img src="/icons/logo.svg" alt="Home" /></a>
    </div>

    {#each Object.keys(links) as href (href)}
      <div class="link">
        <a
          {href}
          class:latitude={links[href].label === 'About Us'}
          data-sveltekit-preload-code="eager">
          {#if links[href].img}
            <img
              src={links[href].img}
              class="header-img"
              alt={links[href].label} />
          {/if}
          <span class:link-w-img={links[href].img}>
            {links[href].label}
          </span>
          <span class="line" class:active={path === href} />
        </a>
      </div>
    {/each}
  </div>
</nav>

<style>
nav {
  display: none;
  position: sticky;
  align-items: center;
  top: -100px;
  left: 0;
  right: 0;
  height: 200px;
  z-index: 5;
}

@media only screen and (min-width: 1024px) {
  nav {
    display: flex;
  }
}

.header-img {
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(0.1em);
  user-select: none;
}

.link-w-img {
  padding-left: 1.7rem;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.bg-mask {
  position: fixed;
  width: 100vw;
  height: 100px;
  top: 0;
  left: 0;
}

.bottomLine {
  border-bottom: 1px solid rgba(90, 88, 107, 0.6);
}

.link a {
  position: relative;
}

.line.active {
  display: inline-block;
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  bottom: -10px;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    #ff560e -15.86%,
    #ff001f 37.83%,
    #8802c8 121.66%,
    #6202fe 152.75%
  );
}

.navigationContent {
  position: sticky;
  height: 100px;
  top: 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 100px 5px 100px;
  font-size: 18px;
}

div.logo {
  flex: 1;
  padding-top: 6px;
}

nav :global(a) {
  margin-right: 20px;
}

.latitude {
  position: relative;
  margin-right: 30px;
}
</style>
