<script lang="ts">
import MobileLink from './Links/MobileLink.svelte'
import { page } from '$app/stores'

let path: string = ''
let open = false

page.subscribe((page) => {
  path = page.url.pathname
  open = false
})

let yScroll = 0
</script>

<svelte:window bind:scrollY={yScroll} />

<nav
  style={yScroll > 60 || open
    ? 'background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%);'
    : 'background-color:transparent'}
  class="mobile-nav"
  class:bottomLine={path !== '/' && path !== '/platform' && yScroll <= 35}>
  <a href="/"><img src="/icons/logo.svg" alt="Home" /></a>
  <img
    src={open ? '/icons/close.svg' : '/icons/hamburger.svg'}
    alt="Menu"
    class="menu"
    on:click={() => (open = !open)}
    role="presentation" />
</nav>

<div class="menuContainer" class:open>
  <div class="menuScroll">
    <!-- <MobileLink href="/sign-in" showArrow={false}><span>Sign in</span></MobileLink> -->
    <MobileLink href="/solutions" showArrow={false}>Services</MobileLink>
    <MobileLink href="/platform" showArrow={false}>Lumiere</MobileLink>
    <MobileLink href="/case-studies" showArrow={false}>Case Studies</MobileLink>
    <MobileLink href="/about" showArrow={false}>
      About&nbsp;
      <span class="latitude">Us</span>
    </MobileLink>
    <MobileLink href="/contact-us" showArrow={false}>Contact</MobileLink>
  </div>
</div>

<style>
nav {
  display: flex;
  position: sticky;
  align-items: center;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  height: 36px;
  padding: 18px 24px 24px 24px;
  z-index: 5;
}

.nav-img {
  width: 1.3rem;
  height: 1.3rem;
  transform: translateY(0.1em);
}

.bottomLine {
  border-bottom: 1px solid rgba(90, 88, 107, 0.6);
}

.menuScroll {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.menuContainer {
  position: fixed;
  visibility: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
  margin-top: 70px;
  transition:
    visibility 0.2s linear,
    opacity 0.2s linear;
}

.open {
  visibility: visible;
  opacity: 1;
  z-index: 5;
}

.menu {
  display: block;
  position: absolute;
  top: 22px;
  right: 24px;
}

.latitude {
  position: relative;
}

@media only screen and (min-width: 1024px) {
  nav {
    display: none;
  }

  .open {
    visibility: hidden;
    opacity: 0;
  }
}
</style>
